import { Box, useToast } from '@chakra-ui/react';
import { Form, Select } from 'antd';
import { usePathname } from 'next/navigation';
import { parseCookies } from 'nookies';
import React, { useEffect, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { ReactComponent as ChevropDown } from '@/app/_icons/arrow-down.svg';
import {
  COOKIE_NAME_DEALERSHIP_ID,
  ENQUIRY_FORM_FOR_NEW_CARS,
  ENQUIRY_FORM_FOR_PARTS,
  ENQUIRY_FORM_FOR_SERVICE,
} from '@/constants/dealership/dealership.constants';
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
} from '@/constants/enquiry-forms/enquiry-forms.constants';
import { useDealersInfo } from '@/hooks/dealers/dealers.hook';
import { useDealershipInfo } from '@/hooks/dealership/dealership.hook';
import { useEnquireForm } from '@/hooks/enquire-form/enquire-form.hook';
import { useRoutesConfig } from '@/hooks/routes-config';
import { getDealers } from '@/services/dealers/dealers.service';

import FormStyles from '../../../custom-form-control.module.scss';

import './dealers-select.scss';

const serviceMap = {
  [ENQUIRY_FORM_FOR_NEW_CARS]: 'New Cars',
  [ENQUIRY_FORM_FOR_SERVICE]: 'Service',
  [ENQUIRY_FORM_FOR_PARTS]: 'Parts',
};

const matchesService = (
  serviceArray: string[],
  serviceToMatch: keyof typeof serviceMap,
) => {
  return serviceArray.includes(serviceMap[serviceToMatch]);
};

interface DealersSelectNewCarsProps {
  name?: string;
  onChange?: (value: any) => void;
  register: UseFormRegister<any>;
  required?: boolean;
  availableDealershipIds?: string[];
  isService?: boolean;
  isParts?: boolean;
}

export const DealersSelectNewCars: React.FC<DealersSelectNewCarsProps> = ({
  name = 'dealership',
  onChange,
  register,
  required = false,
  availableDealershipIds,
  isParts,
  isService,
}) => {
  const { dealersInfo, setDealersInfo } = useDealersInfo();
  const { selectedDealership, setSelectedDealership } = useEnquireForm();
  const { dealershipInfo } = useDealershipInfo();
  const routers = useRoutesConfig();
  const pathname = usePathname() || '';
  const [hasCookieDealership, setHasCookieDealership] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedProvince, setSelectedProvince] = useState<number | null>(null);
  const toast = useToast();

  useEffect(() => {
    const initializeDealers = async () => {
      try {
        const dealers = await getDealers();

        const cookies = parseCookies();
        const kiaDealarshipId = cookies[COOKIE_NAME_DEALERSHIP_ID];

        if (kiaDealarshipId && dealers.length > 0) {
          const selectedDealer = dealers.find(
            (dealer) => dealer?.id === kiaDealarshipId,
          );

          if (selectedDealer) {
            setSelectedDealership(selectedDealer as any);
            setHasCookieDealership(true);
            onChange?.(selectedDealer.id);
            register(name).onChange({
              target: { name, value: selectedDealer.id },
            });
          }
        }

        const newCarsUrl = pathname.includes(`${routers.newCars.path}/`);

        const filteredDealers = newCarsUrl
          ? dealers.filter(
              (dealer) =>
                dealer?.enquiry_form_services &&
                matchesService(
                  dealer.enquiry_form_services,
                  ENQUIRY_FORM_FOR_NEW_CARS,
                ),
            )
          : dealers;

        setDealersInfo(filteredDealers as any);
      } catch (error) {
        toast({
          title: ERROR_TITLE,
          description: ERROR_DESCRIPTION,
          status: 'error',
        });
      }
    };

    initializeDealers();

    if (
      dealershipInfo?.id &&
      pathname?.indexOf(`${routers.dealershipDetail.path}/`) !== -1
    ) {
      handleDealershipChange(dealershipInfo?.id);
    }

    return () => {
      setSelectedDealership(null);
    };
  }, []);

  const handleDealershipChange = (value: string) => {
    if (!value || !dealersInfo) {
      setError('Please select a dealer');
      setSelectedDealership(null);
      onChange?.('');
      return;
    }

    const selectedDealer = dealersInfo.find((dealer) => dealer?.id === value);

    if (selectedDealer) {
      setError(null);
      setSelectedDealership(selectedDealer);
      onChange?.(value);
    }
  };

  let filteredDealers = dealersInfo || [];

  if (availableDealershipIds?.length) {
    filteredDealers = filteredDealers.filter(
      (dealer) => dealer && availableDealershipIds.includes(dealer.id),
    );
  }

  if (selectedProvince) {
    filteredDealers = filteredDealers.filter(
      (dealer) => dealer?.city?.province?.id === selectedProvince,
    );
  }

  // Filter out dealerships for services:
  if (isService) {
    filteredDealers = filteredDealers.filter(
      (dealer) =>
        dealer?.enquiry_form_services &&
        matchesService(dealer.enquiry_form_services, ENQUIRY_FORM_FOR_SERVICE),
    );
  }

  // Filter out dealerships for parts:
  if (isParts) {
    filteredDealers = filteredDealers.filter(
      (dealer) =>
        dealer?.enquiry_form_services &&
        matchesService(dealer.enquiry_form_services, ENQUIRY_FORM_FOR_PARTS),
    );
  }

  const provinces = React.useMemo(() => {
    if (!filteredDealers) return [];

    const uniqueProvinces = new Map();

    filteredDealers.forEach((dealer) => {
      if (dealer?.city?.province) {
        uniqueProvinces.set(dealer.city.province.id, dealer.city.province);
      }
    });
    return Array.from(uniqueProvinces.values());
  }, [filteredDealers]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleProvinceChange = (provinceId: number) => {
    setSelectedProvince(provinceId);
    setSelectedDealership(null);
    handleDealershipChange('');
  };

  if (hasCookieDealership && selectedDealership?.name) {
    return (
      <Box className="customSelect">
        <div className={FormStyles.formInput}>{selectedDealership.name}</div>
        <input
          type="hidden"
          value={selectedDealership.id}
          {...register(name, {
            required: required && 'Please select a dealer',
          })}
        />
      </Box>
    );
  }

  return (
    <Box className="customSelect">
      <Form.Item label="Select Province" className={FormStyles.formLabel}>
        <Select
          suffixIcon={<ChevropDown width={12} height={12} />}
          placeholder="All Provinces"
          className={FormStyles.formInput}
          value={selectedProvince}
          onChange={handleProvinceChange}
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          allowClear
          aria-label="province-select-label"
        >
          {provinces.map((province) => (
            <Select.Option
              key={province.id}
              value={province.id}
              label={province.name}
            >
              {province.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={'Select Dealership*'} className={FormStyles.formLabel}>
        <Select
          {...register(name, {
            required: required && 'Please select a dealer',
          })}
          suffixIcon={<ChevropDown width={12} height={12} />}
          showSearch
          placeholder="Select a dealership"
          optionFilterProp="children"
          filterOption={filterOption}
          className={FormStyles.formInput}
          value={selectedDealership?.id}
          onChange={handleDealershipChange}
          status={error ? 'error' : undefined}
          aria-label="dealership-select-label"
        >
          {filteredDealers.map((dealer) =>
            dealer && dealer?.name && dealer?.id ? (
              <Select.Option
                key={dealer.id}
                value={dealer.id}
                label={dealer.name}
              >
                {dealer.name}
              </Select.Option>
            ) : null,
          )}
        </Select>
      </Form.Item>
    </Box>
  );
};
